Modal.Title.h5.modal-title {
  margin: auto;
  text-align: center;
}

/* .inputDate {
  margin-left: 100px;

  height: 200px;
  width: 600px;
} */

Modal.model-content {
  background-color: #343a40;
}
/* span .my-modal-header {
  
} */
/* 
.bg {
  overlay: {
    background: "#FFFF00";
  }
} */

.customOverlay {
  background: rgba(36, 123, 160, 0.7);
}
.customModal {
  background: #282c34;
}
