.navbar {
  height: 60px;
  overflow: hidden;
  border: none;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  box-sizing: border-box;
  z-index: 90;
  box-shadow: -1px 0 8px 4px black;
}


.ToolbarText {
  color: white;
  font-size: 54px;
  font-weight: bold;
}

.Container {
  justify-content: flex-start;
  flex-direction: row;
}

.icon {
  color: white;
  position: relative;
  width: 150%;
  height: 150%;
  cursor: pointer;
  left: -5px;
  outline: none;
}

.navbar-show {
  color: white;
  border: none;
  background: transparent;
  padding: 4px 8px;
  outline: none;
  direction: rtl;
  box-shadow: 0 2px 3px -2px var(--dark);
}

.navbar-brand {
  color: white;
}

.navbar-burger {
  margin-left: 18px;
  cursor: pointer;
}