.sign-page {
  position: relative;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    min-height: calc(100vh - 100px);
  }

  .sign-container {
    position: relative;
    height: auto;
    width: auto;
    max-width: 320px;
    float: left;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: 768px) {
      position: absolute;
      width: 80%;
    }
  }

  input[type="text"] {
    border-radius: 0;
    outline: none;
    color: #fff;

    &::placeholder {
      color: whitesmoke;
    }

    &:focus {
      border: none;
      box-shadow: 0 0 8px 1px var(--pink);
    }
  }

  p {
    margin-top: 20px;
    font-size: 14px;
    line-height: 18px;
    color: var(--light-grey);
  }
}

.bg-video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  pointer-events: none;
  opacity: 0.9;
  object-fit: cover;
  animation-name: fadeOut;
  animation-duration: 10s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

@keyframes fadeOut {
  0% {
    opacity: 0.9;
  }
  100% {
    opacity: 0;
    display: none;
  }
}

