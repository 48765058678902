.chat-container {

  position: fixed;
  left: 0;
  height: calc(100vh - 200px);
  width: 280px;
  top: 100px;
  padding: 40px 20px 0;
  //background-color: rgba(0, 0, 0, 0.25);

  @media (max-width: 920px) {
    display: none;
  }

  h4 {
    margin-bottom: 10px;
  }

  .chat {

    height: 100%;
    width: 100%;

    .chat-log {
      height: 80%;
      width: 100%;
      overflow-y: auto;

      &::-webkit-scrollbar {
        display: none;
      }

      .chat-message {
        background-color: rgba(25, 0, 25, 0.75);
        padding: 4px 8px;
        display: inline-block;
        border-radius: 4px;
        margin-bottom: 8px;
      }
    }

    .chat-input, .chat-name {
      border-radius: 4px;
      padding: 4px 8px;
      margin-top: 10px;
      width: 100%;
      background-color: rgba(25, 0, 25, 0.75);
      color: #fff;
      text-align: right;

      input, button {
        &::placeholder {
          color: whitesmoke;
        }

        color: #fff !important;
      }
    }
  }
}