.sidebar-wrapper {
  min-height: 100vh !important;
  width: 100vw;
  margin-left: -1rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;

  .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
  }
}

.page-content-wrapper {
  min-width: 0;
  width: 100%;
}

.sidebar-right {
  position: fixed;
  background-color: var(--dark-grey);
  top: 0;
  bottom: 0;
  right: -64px;
  z-index: 80;
  padding-top: 60px;
  //left: calc(100% - 48px);
  font-size: 1.25rem;
  line-height: 1;
  box-shadow: -1px 0 8px 1px var(--dark);
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  transition: right .5s ease-in-out;

  @media (max-width: 768px) {
    //display: none;
  }

  &.open {
    right: 0;
  }
}

.wrapper {
  position: absolute;
  transform: rotate(90deg) translate(0, -10px);
  transform-origin: left bottom;
  width: calc(100vh - 140px);

  .contact {
    margin: 10px;
  }

  .contact:nth-child(3) {
    margin-right: 20px;
  }
}

a {
  color: #FFF;
}

.share {
  margin: 0 10px 0 0;
  transform: rotate(-90deg);
  outline: none;
}

.sidebar-right .icon {
  font-size: 36px;
  line-height: 1;
  width: 36px;
  height: 36px;
}

.copy {
  background: transparent;
  outline: none !important;
  border: none !important;
  margin: 0 20px 0 0;
}

.app-icon {
  width: 44px;
  height: auto;
}

.btn.bottom {
  position: absolute;
  bottom: 0;
  padding: 4px;
}

.btn.top {
  margin-top: 10px;
}

.btn {

}