footer {
    height: 40px;
    text-align: center;
    border: none;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 14px;
    line-height: 2;
}