.live-container {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 10;

  .live-stream {
    display: inline-block;
    text-align: center;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 1;
    padding: 10px;
    @media (max-width: 768px) {
      min-height: 200px;
    }

    .iframe-container {
      position: relative;
      overflow: hidden;
      right: 0;
      margin: auto;
      top: 20px;
      //top: 50%;
      //transform: translateY(-50%);
      pointer-events: all;

      iframe {
        pointer-events: all;
        position: absolute;
        right: 0;
        top: 0;
        width: calc(100% - 40px);
        height: 100%;
        z-index: 100;
      }
    }

  }

  .live-stream #twitch-embed {
    margin: auto;
    padding: 20px 0 0;
  }

  @media (min-width: 768px) {
    .live-stream #twitch-embed {
      padding: 40px 20px 20px;
    }
  }

  .interactive {
    position: relative;
    left: 0;
    right: 0;
    z-index: 10;
  }

  .links-container {
    position: relative;
    opacity: 0;
    pointer-events: none;
    transition: opacity 1s ease-in-out;
    height: auto;
    background-color: rgba(0, 0, 0, 0.4);
    bottom: 0;

    &.show {
      opacity: 1;
      pointer-events: all;
    }

    @media (max-width: 768px) {
      margin-top: 50px;
      margin-bottom: 50px;
      background-color: rgba(0, 0, 0, 0.8);
    }

    @media (min-width: 769px) {
      position: absolute;
    }

    .links-item {
      text-align: center;
      padding: 15px 10px;
      min-width: 160px;

      .link {
        height: auto;
        width: 100%;
        display: block;
        padding: 10px;
        border-radius: 300px;
        cursor: pointer;
      }

      @media (max-width: 768px) {
        width: 100%;
        padding: 10px 5px;
      }
    }
  }
}

.live-timing {
  position: fixed;
  right: 10px;
  top: 63px;
  @media (min-width: 1024px) {
    right: 60px;
  }
}

.side-bar {
  z-index: 100;
}

#twitch-player-embed {
  margin: auto;
}