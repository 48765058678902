:root {
  --grey: #282c34;
  --dark-grey: #343a40;
  --light-grey: #6c757d;
  --pink: #e6456a;
  --violet: #5e0087;
}

body {
  margin: 0;
  font-family: "Segoe UI", "Roboto", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.color-invert {
  filter: invert(1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.App {
  text-align: center;
  background-color: var(--grey);
}

@media (max-width: 768px) {
  html, body, .App {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.content {
  background-color: var(--grey);
  width: 100vw;
  border: none;
  color: rgb(233, 233, 233);
  position: relative;

  .content-background {

    width: 100%;
    height: 100%;
    position: absolute;
    background: transparent center/cover no-repeat;
    display: none;
    filter: blur(0);
    transition: filter 2s ease-in-out;

    &.desktop {
      @media (min-width: 920px) {
        display: block;
      }
    }

    &.mobile {
      @media (max-width: 919px) {
        display: block;
      }
    }

    &.blur {
      filter: blur(40px);
    }
  }

  @media (min-width: 769px) {
    height: calc(100vh - 100px);
  }

  &.narrow {
    @media (min-width: 769px) {
      width: calc(100% - 54px);
    }
  }

  @media (max-width: 768px) {
    min-height: calc(100vh - 100px);
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.flex-full {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

@media (max-width: 768px) {
  .flex-full {
    flex-wrap: wrap;
  }
}

.pink {
  color: var(--pink);
}

.link {
  cursor: pointer;
}

a:hover, .link:hover {
  color: var(--pink);
  text-decoration: none;
}

.bg-dark {
  background-color: rgba(17, 17, 17, 1) !important;
}

.bg-trans-white {
  background-color: rgba(250, 250, 250, 0.1) !important;
}

.bg-trans-grey {
  background-color: rgba(33, 37, 41, 0.9) !important;
}

.link-button {
  background: transparent;
  color: var(--grey);
  border: none;

  &:hover, &:active, &:focus {
    outline: none;
    color: #ffffff;
  }
}